div.loadingModalContainer {
	display: flex;
	flex-direction: column;
	position: fixed;
	top: 0;
	bottom: 0;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.75);
	z-index: 1;
	animation: fade-in 0.25s;
}

@keyframes fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

div.loadingModal {
	margin: auto;
}

p.loadingText {
	font-size: 1.6rem;
}

.loadingAnimation {
	width: fit-content;
	margin: auto;
}
