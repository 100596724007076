div.resultLogContainer {
	display: flex;
	flex-direction: column;
	width: 80%;
	margin: 1rem auto;
}

div.resultLog {
	display: flex;
	flex-direction: column;
	height: 40rem;
	background-color: white;
	border: 2px solid black;
	overflow-y: scroll;
}

div.resultLogEntryContainer {
	display: flex;
	align-items: center;
}

div.resultLogHeader {
	display: flex;
	height: 2.5rem;
	align-items: center;
}

div.resultLogEntry {
	margin: 1rem;
	border-bottom: 1px solid black;
}

div.resultLogEntryTimestamped {
	display: flex;
}

p.resultLogTimestamp {
	color: black;
	font-size: 1.6rem;
	width: 11rem;
}

p.resultLogText {
	color: black;
	font-size: 1.6rem;
	margin: auto;
}

div.batchProcessingToggle {
	display: flex;
	position: absolute;
	top: 0;
	left: 1rem;
	align-items: center;
}

p.batchProcessingToggleText {
	font-size: 1.2rem;
}
