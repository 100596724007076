* {
    margin: 0;
    padding: 0;
}

html {
    font-size: 62.5%;
}

#root {
    display: flex;
    flex-direction: column;
    background-color: #455660;
    min-height: 100vh;
    color: white;
    font-family: Arial;
}

div.centered-container {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    margin: auto;
}

div.row {
    flex-direction: row;
}

div.col {
    flex-direction: column;
}

button {
    background-color: white;
    cursor: pointer;
}

button.program {
    width: 30rem;
    height: 8rem;
    border-radius: 1rem;
    font-weight: 600;
    font-size: 1.6rem;
    border: 2px solid black;
}

div.program-name {
    display: flex;
    width: 30rem;
    height: 8rem;
    margin: 1rem auto;
    border-radius: 1rem;
    font-weight: 600;
    background-color: white;
    color: black;
    font-size: 1.6rem;
    border: 2px solid black;
    text-align: center;
    align-items: center;
}

div.program-name p {
    width: 100%;
}

h1 {
    text-align: center;
    font-size: 5rem;
    font-weight: 900;
    margin: 1rem;
    -webkit-text-stroke: 2px black;
}

button.run {
    width: 40rem;
    margin: auto;
    padding: 1rem 2rem;
    border-radius: 1rem;
    font-weight: 600;
    font-size: 1.6rem;
    border: 2px solid black;
}

/* button.run:active {
	-webkit-box-shadow: inset 0px 0px 5px #c1c1c1;
	-moz-box-shadow: inset 0px 0px 5px #c1c1c1;
	box-shadow: inset 0px 0px 5px #c1c1c1;
	outline: none;
} */

div.program {
    display: flex;
    flex-direction: column;
    width: 100%;
}

input {
    margin: auto;
}

/* .MuiMenu-paper {
    background-color: rgba(0, 0, 0);
    border: 1px solid white;
    width: 21.8rem;
    
} */

/* .MuiButtonBase-root {
	text-align: center;
	margin: auto;
}

.MuiMenuItem-root {
	text-align: center;
	margin: auto;
} */
